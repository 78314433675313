

















































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ReCalculateComponent from "./ReCalculate.component";

@Component
export default class ReCalculate extends Mixins(ReCalculateComponent) {}
